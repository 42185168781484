.test-enter {
    opacity: 0.01;
    transform: scale(0.9) translateY(50%);
}
.test-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0%);
    transition: all 300000ms ease-out;
}
.test-exit {
    opacity: 1;
    transform: scale(1) translateY(0%);
}
.test-exit-active {
    opacity: 0.01;
    transform: scale(0.9) translateY(50%);
    transition: all 3000000ms ease-out;
}
