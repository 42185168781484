@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

.ril-toolbar {
  background-color: rgba(0, 0, 0, 0);
}

:root {
  /*Dimensione Font SectionPresentation aka main menu*/
  --mainMenuText: 100px;
  /**/
  --enormousText: 100px;
  --veryBigText: 64px;
  --bigMediumText: 48px;
  --mediumBigText: 32px;
  --mediumText: 20px;
  --smallText: 16px;
  --floorTextSize: 32px;
  --apartmentTitle: 64px;
  --apartmentNumber: 100px;
  --apartmentMediumText: 20px;
  --bottomMenuItem: 16px;
  --eventoText: 32px;
  --eventoDate: 20px;
  --eventoInnerTitle: 64px;
  --eventoInnerText: 16px;
  --headerText: 14px;
  --apartmentSingleRoom: 16px;
  --apartmentDescriptionText: 16px;
  --calendarioEventiTitle: 64px;
  --eventTitle: 64px;
  --particularSectionTitle: 48px;
  --headerElement: 32px;
  --arrowWrite: 20px;
}
html,
body {
  width: 100%;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif !important;
  --bigText: 5.5em;
  /* overflow: hidden; */
}
body::-webkit-scrollbar {
  /* width: 0px;
    background: transparent; */
  display: none;
}

.mobile {
  display: none;
}
.mobileFlex {
  display: none;
}
/* @media (max-width: 576px){
    .mobile{
        height: 100vh;
        width: 100vw;
        font-size: 64px;
        line-height: 86px;
        z-index: 30000;
        background-color: url(/static/media/texture2.0.a377dbca.png);
    }
    .mobileFlex{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 30000;
        color: white;
    }
} */
@media (max-width: 576px) {
  .mobile {
    height: 100vh;
    width: 100vw;
    font-size: 64px;
    line-height: 86px;
    z-index: 30000;
    background-color: white;
    background-image: url(/static/media/texture2.0.a377dbca.png);
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .mobileFlex {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30000;
    color: #91855d;
  }
  .logoMobile {
    position: fixed;
    height: 200px;
    width: 500px;
    background-image: url("../img/Export_Asset/Layer 2.png");
    background-repeat: no-repeat;
    background-size: contain;
    left: 50%;
    margin-left: -250px;
    top: 46%;
    margin-top: -100px;
    zoom: 59%;
    /* zoom: 59%; */
  }
  .logoMobile::after {
    z-index: 100000;
    content: "Visita il sito da desktop";
    position: absolute;
    font-size: 36px;
    line-height: 64px;
    height: 50px;
    width: 500px;
    left: 50%;
    text-align: center;
    margin-left: -250px;
    margin-top: 35vh;
    color: #91855d;
    /* left: 50%;
        top: 110%; */
    /* margin-left: -500px;
        margin-top: -50px; */
  }
}
.language-selection {
  padding: 4px;
  text-decoration: underline;
  cursor: pointer;
}
.language-selection:hover {
  opacity: 0.5;
}

span {
  height: 100%;
}
div.root {
  height: 100%;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (min-width: 601px) {
  .home {
    position: fixed;
    background-image: url("../img/riempitivi/WEB/texture2.0.png");
    /* background-image: url("../img/sfondo_video.mkv"); */
    background-repeat: no-repeat;
    background-size: 100vw;
    background-position: center;
    opacity: 1;
    /* z-index: -1; */
    height: 120%;
    width: 110%;
    zoom: 110%;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-left: -5%;
    margin-top: -5%;
    margin-bottom: 5%;
    /* zoom: 105%;
        margin-left: -0%;
        margin-top: -0%; */
  }
}

@media screen and (max-width: 600px) {
  .home {
    position: fixed;
    background-image: url("../img/riempitivi/WEB/texture2.0.png");
    background-repeat: no-repeat;
    background-size: unset;
    background-position: center;
    opacity: 1;
    height: 100%;
    width: 100%;
  }
}

h1 {
  height: 100%;
  display: block;
  text-align: center;
  font-size: 3em;
  margin-top: 20%;
  margin-bottom: 0.67em;
  margin-left: -5%;
  margin-right: 0;
  font-style: normal;
}

@media screen and (min-width: 601px) {
  .sectionTitleBox {
    position: absolute;
    width: 100%;
    margin-left: -0%;
    top: 40%;
  }
}

@media screen and (max-width: 600px) {
  .sectionTitleBox {
    position: absolute;
    width: 100%;
    margin-left: -0%;
    top: 43%;
  }
}

@media screen and (min-width: 601px) {
  .sectionTitle {
    font-style: normal;
    position: relative;
    color: white;
    height: 100%;
    width: 50%;
    text-align: center;
    font-size: var(--mainMenuText);
    margin-top: 0%;
    margin-bottom: 0em;
    margin-left: -25%;
    margin-right: 0;
    left: 50%;
    top: 0%;
    z-index: -0;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .sectionTitle {
    font-style: normal;
    position: relative;
    color: white;
    /* height: 100%; */
    /* width: 50%; */
    text-align: center;
    font-size: 56px;
    margin-top: 0%;
    margin-bottom: 0em;
    /* margin-left: -25%; */
    margin-right: 0;
    /* left: 50%; */
    top: 0%;
    z-index: -0;
    display: block;
  }
}

/* .apartments {
    font-size: 48px;
} */

.sectionTitleContact {
  font-style: normal;
  position: fixed;
  color: #91855d;
  height: 100%;
  width: 50%;
  text-align: center;
  font-size: var(--mainMenuText);
  margin-top: 0%;
  margin-bottom: 0em;
  margin-left: -25%;
  margin-right: 0;
  left: 50%;
  top: 40%;
  z-index: 0;
  display: block;
}

.sectionTitle-appear,
.sectionTitle-enter {
  opacity: 0;
  transform: translateY(400px);
}

.sectionTitle-appear-active,
.sectionTitle-enter.sectionTitle-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: 500ms linear;
}

.sectionTitle-exit {
  opacity: 1;
  transform: translateY(0);
}
.sectionTitle-exit.sectionTitle-exit-active {
  transform: translateY(-400px);
  opacity: 0;
  transition: 500ms linear;
}
.sectionTitle-leave {
  opacity: 1;
  transform: translateY(0);
}
.sectionTitle-leave.sectionTitle-leave-active {
  transform: translateY(-400px);
  opacity: 0;
  transition: 500ms linear;
}

/*fine primo*/

.sectionTitleReverse-appear,
.sectionTitleReverse-enter {
  opacity: 0;
  transform: translateY(-400px);
}

.sectionTitleReverse-appear-active,
.sectionTitleReverse-enter.sectionTitleReverse-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: 800ms ease-in-out;
}

.sectionTitleReverse-exit {
  opacity: 1;
  transform: translateY(0);
}
.sectionTitleReverse-exit.sectionTitleReverse-exit-active {
  transform: translateY(400px);
  opacity: 0;
  transition: 800ms ease-in-out;
}

.sectionTitleReverse-leave {
  opacity: 1;
  transform: translateY(0);
}
.sectionTitleReverse-leave.sectionTitleReverse-leave-active {
  transform: translateY(400px);
  opacity: 0;
  transition: 800ms ease-in-out;
}
/*fine secondo*/

.contactMainTitle-appear,
.contactMainTitle-enter {
  opacity: 0;
  transform: translateY(25vw) translateX(39.5vw) scale(2, 2);
  transition-property: translateY, translateX, scale;
  transition-duration: 1000ms;
  transition-delay: 1000ms, 1000ms, 1000ms;
  transition: 1000ms linear, 1000ms linear, 1000ms linear;
}

.contactMainTitle-appear-active,
.contactMainTitle-enter.contactMainTitle-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.contactMainTitle-exit {
  opacity: 1;
  transform: translateY(25vw) translateX(39.5vw) scale(2, 2);
  transition-property: translateY, translateX, scale;
  transition-duration: 1000ms;
  transition-delay: 1000ms, 1000ms, 1000ms;
  transition: 1000ms linear, 1000ms linear, 1000ms linear;
}
.contactMainTitle-exitReal.contactMainTitle-exit-active {
  transform: translateY(25vh) translateX(39.5vw);
  opacity: 0;
  transition: 1000ms linear;
}

/*Fine terzo*/

.contactMainTitleReverse-appear,
.contactMainTitleReverse-enter {
  opacity: 1;
  transform: translateY(30vh) translateX(31.5vw) scale(2, 2);
  transition-property: translateY, translateX, scale;
  transition-duration: 800ms;
  transition: ease-in-out;
}

.contactMainTitleReverse-appear-active,
.contactMainTitleReverse-enter.contactMainTitleReverse-enter-active {
  opacity: 1;
  transform: translateY(0) translateX(0) scale(1, 1);
  transition: 600ms ease-in-out, 600ms ease-in-out, 600ms ease-in-out;
  /* transition-delay: 10000ms,1000ms, 1000ms; */

  /* transform: translateY(0); */
  /* transition: 800ms linear; */
  transition-delay: 1200ms;
}

.contactMainTitleReverse-exit {
  opacity: 1;

  transform: translateY(25vw) translateX(39.5vw) scale(2, 2);
  transition-property: translateY, translateX, scale;
  transition-duration: 1000ms;
  transition-delay: 1000ms, 1000ms, 1000ms;
  transition: 1000ms linear, 1000ms linear, 1000ms linear;
  transform: translateY(0);
  transition: 1000ms linear;
  transition-delay: 1000ms;
}
.contactMainTitleReverse-exitReal.contactMainTitleReverse-exit-active {
  transform: translateY(0) translateX(0);
  opacity: 0;
  transition: 1000ms linear;
  transition-delay: 1000ms;
}

@media screen and (max-width: 600px) {
  .contactMainTitle-appear,
  .contactMainTitle-enter {
    transform: translateY(25vw) translateX(5vw) scale(1.3, 1.3);
  }
  .contactMainTitle-exit {
    transform: translateY(25vw) translateX(5vw) scale(1.3, 1.3);
  }
  .contactMainTitleReverse-appear,
  .contactMainTitleReverse-enter {
    transform: translateY(30vh) translateX(5vw) scale(1.3, 1.3);
  }
  .contactMainTitleReverse-exit {
    transform: translateY(25vw) translateX(5vw) scale(1.3, 1.3);
  }
}

@media screen and (min-width: 601px) {
  .menuRightButton {
    position: relative;
    height: 60px;
    width: 302px;
    background-image: url("../img/Export_Asset/Forward_B_1.png");
    background-repeat: no-repeat;
    left: 50%;
    margin-left: -151px;
    top: 15vh;
    z-index: 1050;
    zoom: 60%;
    cursor: pointer;
    transition: transform 300ms ease-in-out;
  }
}

@media screen and (max-width: 600px) {
  .menuRightButton {
    position: relative;
    height: 40px;
    width: 110px;
    background-image: url("../img/Export_Asset/Forward_B_1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    margin-left: -55px;
    top: 24px;
    z-index: 1050;
    /* zoom: 60%; */
    cursor: pointer;
    transition: transform 300ms ease-in-out;
  }
}

.menuRightButton:hover {
  transform: scaleX(1.5);
}

.menuDownButtonDecentered {
  position: fixed;
  height: 250px;
  width: 50px;
  background-image: url("../img/Export_Asset/Down_B_2@2x.png");
  background-repeat: no-repeat;
  background-size: 45px;
  background-position: center;
  left: 66%;
  margin-left: -25px;
  bottom: 15vh;
  transform: rotate(0deg);
  z-index: 20000;
  zoom: 30%;
  cursor: pointer;
  transition: transform 500ms ease-in-out;
}

@media screen and (max-width: 600px) {
  .menuDownButtonDecentered {
    left: 50%;
  }
}

.menuDownButtonDecentered:hover {
  transform: scaleY(1.3);
}

.menuDownButtonDecentered-appear,
.menuDownButtonDecentered-enter {
  opacity: 0;
  transform: translateY(120vh);
}

.menuDownButtonDecentered-appear-active,
.menuDownButtonDecentered-enter.menuDownButtonDecentered-enter-active {
  opacity: 1;

  transform: translateY(100vh);
  transition: 500ms linear;
}

.menuDownButtonDecentered-exit {
  opacity: 1;
  transform: translateY(0);
}
.menuDownButtonDecentered.menuDownButtonDecentered-exit-active {
  transform: translateY(-400px);
  opacity: 0;
  transition: 500ms linear;
}
.menuDownButtonDecentered-leave {
  opacity: 1;
  transform: translateY(0);
}
.menuDownButtonDecentered-leave.menuDownButtonDecentered-leave-active {
  transform: translateY(-400px);
  opacity: 0;
  transition: 500ms linear;
}

.menuDownButtonWrite {
  position: fixed;
  text-align: center;
  width: 200px;
  left: 66%;
  margin-left: -100px;
  bottom: 14vh;
  z-index: 1050;
  cursor: pointer;
  color: #91855d;
  transition: 500ms ease-in-out;
}

.moveUp {
  transform: translateY(-15px);
}

.headerBackButtonMobile {
  height: 32px;
  width: 32px;
  margin-left: 32%;
  background-image: url("../img/Export_Asset/Previous_B_1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1100;
  cursor: pointer;
  transform: rotate(90deg);
}

.headerBackButtonMobileInverted {
  height: 32px;
  width: 32px;
  margin-left: 32%;
  background-image: url("../img/arrow_back_header.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1100;
  cursor: pointer;
}

.menuDownButtonCentered {
  position: fixed;
  height: 100px;
  width: 50px;
  background-image: url("../img/Export_Asset/Previous_B_1.svg");
  background-repeat: no-repeat;
  left: 50%;
  margin-left: -25px;
  top: 92%;
  z-index: 1100;
  zoom: 70%;
  cursor: pointer;
}

.menuBackButton {
  position: fixed;
  height: 60px;
  width: 302px;
  background-image: url("../img/Export_Asset/Forward_B_1.png");
  background-repeat: no-repeat;
  left: 2.5%;
  margin-left: -0px;
  top: 90%;
  transform: rotate(180deg);
  z-index: 0;
  zoom: 25%;
  cursor: pointer;
  transition: transform 250ms ease-in-out;
}

.menuBackButton:hover {
  transform: scaleX(1.3) rotate(180deg);
}

.menuBackButtonWrite {
  position: fixed;
  left: 2.6%;
  margin-left: -0px;
  top: 87%;
  z-index: 0;
  color: white;
  cursor: pointer;
  font-size: var(--arrowWrite);
}

.logoUpAndLeft {
  position: fixed;
  height: 90px;
  width: 302px;
  background-image: url("../img/Export_Asset/Layer -9.png");
  background-repeat: no-repeat;
  left: 02.8%;
  margin-left: 0px;
  top: 05%;
  zoom: 60%;
  cursor: pointer;
  z-index: 900;
}
.logoUpLeftInvertedColor {
  position: fixed;
  height: 259px;
  width: 812px;
  background-image: url("../img/Export_Asset/Layer 2.png");
  background-repeat: no-repeat;
  left: 02.8%;
  margin-left: 0px;
  top: 05%;
  zoom: 19%;
  cursor: pointer;
  z-index: 1500;
}

@media screen and (min-width: 601px) {
  .logoCentered {
    position: fixed;
    height: 400px;
    width: 1000px;
    background-image: url("../img/Export_Asset/Layer 2.png");
    background-repeat: no-repeat;
    background-size: contain;
    left: 50%;
    margin-left: -500px;
    top: 50%;
    margin-top: -200px;
    zoom: 59%;
    zoom: 59%;
  }

  .logoCentered::after {
    content: "Affittiamo l'esclusività";
    position: absolute;
    font-size: 64px;
    height: 100px;
    width: 1000px;
    left: 50%;
    text-align: center;
    margin-left: -500px;
    margin-top: 70vh;
    color: #91855d;
  }
  .logoCentered_en {
    position: fixed;
    height: 400px;
    width: 1000px;
    background-image: url("../img/Export_Asset/Layer 2.png");
    background-repeat: no-repeat;
    background-size: contain;
    left: 50%;
    margin-left: -500px;
    top: 50%;
    margin-top: -200px;
    zoom: 59%;
    zoom: 59%;
  }

  .logoCentered_en::after {
    content: "Renting exclusivity";
    position: absolute;
    font-size: 64px;
    height: 100px;
    width: 1000px;
    left: 50%;
    text-align: center;
    margin-left: -500px;
    margin-top: 70vh;
    color: #91855d;
  }
}

/* mobile */
@media screen and (max-width: 600px) {
  .logoCentered {
    position: fixed;
    height: 200px;
    width: 80%;
    background-image: url("../img/Export_Asset/Layer 2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: 45%;
    margin: 0 10%;
    margin-top: -100px;
  }

  .logoCentered::after {
    content: "Affittiamo l'esclusività";
    position: absolute;
    font-size: 24px;
    height: 100px;
    width: 1000px;
    left: 50%;
    text-align: center;
    margin-left: -500px;
    margin-top: 200px;
    color: #91855d;
  }
}

.logo2 {
  position: fixed;
  height: 67px;
  width: 198px;
  background-image: url("../img/logo.png");
  background-repeat: no-repeat;
  left: 02.5%;
  margin-left: 0px;
  top: 030%;
  cursor: pointer;
  z-index: 1500;
}

.particularSectionPresentationRightText {
  position: fixed;
  height: 100%;
  max-width: 50%;
  width: 50%;
  text-align: center;
  font-size: var(--mediumBigText);
  margin-top: 0%;
  margin-bottom: 0em;
  margin-left: -24.5%;
  margin-right: 0;
  left: 66%;
  top: 40%;
  font-style: normal;
  z-index: -1;
  display: inline;
  overflow-wrap: break-word;
  color: #91855d;
  text-align: left;
}
.particularSectionPresentationRightText1 {
  position: fixed;
  height: 100%;
  max-width: 50%;
  width: 50%;
  text-align: center;
  font-size: var(--smallText);
  line-height: 28px;
  margin-bottom: 0em;
  margin-left: -24.5%;
  margin-right: 0;
  left: 66%;
  top: 48%;
  font-style: normal;
  z-index: -1;
  display: inline;
  overflow-wrap: break-word;
  text-align: left;
  font-family: "Montserrat";
  letter-spacing: -1px;
}
.loader {
  position: absolute;
  top: 35%;
  left: calc(66.6% - 50px);
}
.particularSectionPresentationDescription {
  position: relative;
  height: 100%;
  max-width: 50%;
  width: 50%;
  text-align: center;
  font-size: var(--mediumText);
  line-height: 35px;
  margin-top: 5%;
  margin-bottom: 0em;
  margin-left: -24.5%;
  margin-right: 0;
  left: 66%;
  top: 40%;
  font-style: normal;
  z-index: -1;
  display: inline;
  overflow-wrap: break-word;
  text-align: left;
  font-family: "Montserrat";
  letter-spacing: -1px;
}
.mapPageRightText {
  position: fixed;
  height: 600px;
  width: 50%;
  text-align: center;
  left: 66%;
  top: 10%;
  margin-left: -25%;
}
.map {
  position: fixed;
  /* height: 300px;
    width: 600px; */
  background-color: red;
  left: calc(67.5% - 450px);
  /* left: 50%; */
  top: 36%;
  /* margin-left: -300px; */
  z-index: 0;
}
.innerMapCentered {
  position: relative;
  /* height: 300px;
    width: 600px; */
  left: calc(50% - 37.5vw);
  /* left: 50%; */

  /* margin-left: -300px; */
  z-index: 0;
}

.fade-entered {
  opacity: 0;
}

.fade-entering {
  opacity: 0;
  transition: opacity 800ms ease-in-out;
}

.fade-exited {
  opacity: 1;
}
.fade-exiting {
  opacity: 1;
  transition: opacity 800ms ease-in-out;
}

.fade-gallery-entered {
  opacity: 1;
  transition: opacity 800ms ease-in-out;
}

.fade-gallery-entering {
  opacity: 0;
}

.fade-gallery-exited {
  opacity: 0;
  transition: opacity 800ms ease-in-out;
}
.fade-gallery-exiting {
  opacity: 1;
}

/* .fade {
    animation: fadein 2s;
} */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: 500ms linear;
}

.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.fade-leave {
  opacity: 1;
}
.fade-leave.fade-leave-active {
  opacity: 0;
  transition: 500ms linear;
}

/*fine primo fade*/

.fadeDelay-appear,
.fadeDelay-enter {
  opacity: 0;
}

.fadeDelay-appear-active,
.fadeDelay-enter.fadeDelay-enter-active {
  opacity: 1;
  transition: 500ms linear;
  transition-delay: 1300ms;
}

.fadeDelay-exit {
  opacity: 1;
}
.fadeDelay-exit.fadeDelay-exit-active {
  opacity: 0;
  transition: 500ms linear;
}

.fadeLong-appear,
.fadeLong-enter {
  opacity: 0;
}

.fadeLong-appear-active,
.fadeLong-enter.fadeLong-enter-active {
  opacity: 1;
  transition: 750ms linear;
}

.fadeLong-exit {
  opacity: 1;
}
.fadeLong-exit.fadeLong-exit-active {
  opacity: 0;
  transition: 750ms linear;
}
.fadeLong-leave {
  opacity: 1;
}
.fadeLong-leave.fadeLong-leave-active {
  opacity: 0;
  transition: 750ms linear;
}

.event {
  position: fixed;
}

.menuGreyOverlay {
  position: fixed;
  background-color: #6e6a69;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  opacity: 0.4;
}

.Concept {
  position: fixed;
  background-color: black;
  opacity: 0.9;
  height: 100vh;
  width: 100vw;
  zoom: 100%;
}
.Concept::after {
  content: "";
  position: fixed;
  background-image: url("../img/riempitivi/WEB/Vertical WEB/kirill-9uH-hM0VwPg-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  height: 100vh;
  width: 100vw;
}
.Servizi {
  position: fixed;
  background-color: black;
  opacity: 0.9;
  height: 100vh;
  width: 100vw;
}
.Servizi::after {
  content: "";
  position: fixed;
  background-image: url("../img/riempitivi/WEB/thought-catalog-505eectW54k-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.6;
  height: 100vh;
  width: 100vw;
}
.Appartamenti {
  position: fixed;
  background-color: black;
  opacity: 0.9;
  height: 100vh;
  width: 100vw;
}
.Appartamenti::after {
  content: "";
  position: fixed;
  background-image: url("../img/WhatsApp_Image_2020-03-12_at_13.49.45.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.6;
  height: 100vh;
  width: 100vw;
}
.Eventi {
  position: fixed;
  background-color: black;
  opacity: 0.9;
  height: 100vh;
  width: 100vw;
}
.Eventi::after {
  content: "";
  position: fixed;
  background-image: url("../img/riempitivi/WEB/priscilla-du-preez-nF8xhLMmg0c-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.6;
  height: 100vh;
  width: 100vw;
}
.VicinoANoi {
  position: fixed;
  background-color: black;
  opacity: 0.9;
  height: 100vh;
  width: 100vw;
}
.VicinoANoi::after {
  content: "";
  position: fixed;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/_MG_6774-1_01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.7;
  height: 100vh;
  width: 100vw;
}

.menuGreyOverlaySecondaryMenu {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
  color: transparent;
}
.doveSiamo {
  position: fixed;

  z-index: -3;
  height: 97.5vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}
.Concept1 {
  position: fixed;
  background-image: url("../img/riempitivi/WEB/concept1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 1;
  z-index: -3;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}
.Concept2 {
  position: fixed;
  background-image: url("../img/riempitivi/WEB/Vertical WEB/alcoholic-beverages-beverage-bottleneck-373067.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 0.85;
  z-index: -3;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}
.Servizi1 {
  position: fixed;
  background-image: url("../img/riempitivi/WEB/Servizi/taisiia-stupak-DhVOFR_Hmac-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: -3;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}
.Servizi2 {
  position: fixed;
  background-image: url("../img/riempitivi/WEB/Servizi/casual-close-up-colors-1954524.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -443px -0px;
  opacity: 1;
  z-index: -3;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}

.Servizi3 {
  position: fixed;
  background-image: url("../img/riempitivi/WEB/Servizi/aromatherapy-ayurveda-b-3188.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: -3;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}
.Servizi4 {
  position: fixed;
  background-image: url("../img/riempitivi/WEB/Servizi/baskets-cabinet-clean-271711.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -455px -0px;
  opacity: 1;
  z-index: -3;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}
.Servizi5 {
  position: fixed;
  background-image: url("../img/riempitivi/WEB/Servizi/rosebox-BFdSCxmqvYc-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -28px -0px;
  opacity: 1;
  z-index: -3;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}
.Servizi6 {
  position: fixed;
  background-image: url("../img/riempitivi/WEB/Servizi/drew-beamer-Se7vVKzYxTI-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -253px -0px;
  opacity: 1;
  z-index: -3;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}
@media screen and (max-width: 600px) {
  .Concept1 {
    width: 100vw;
  }
  .Concept2 {
    width: 100vw;
  }
  .Servizi1 {
    width: 100vw;
  }
  .Servizi2 {
    width: 100vw;
  }
  .Servizi3 {
    width: 100vw;
  }
  .Servizi4 {
    width: 100vw;
  }
  .Servizi5 {
    width: 100vw;
  }
  .Servizi6 {
    width: 100vw;
  }
}
.DoveSiamo {
  position: fixed;
  background-image: url("../img/riempitivi/WEB/doveSiamo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: -3;
  height: 100vh;
  width: 33.4vw;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
}
.eventoMobile {
  height: 100vh;
  background-image: url("../img/riempitivi/WEB/priscilla-du-preez-nF8xhLMmg0c-unsplash2.0.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -540px -0px;
  zoom: 100%;
  width: 100%;
  opacity: 1;
  background-color: #6e6a69;
  cursor: pointer;
}
.evento1 {
  height: 100vh;
  background-image: url("../img/riempitivi/WEB/priscilla-du-preez-nF8xhLMmg0c-unsplash2.0.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -540px -0px;
  zoom: 100%;
  opacity: 1;
  background-color: #6e6a69;
  cursor: pointer;
}
.horizontal-scroll-wrapper {
  position: absolute;
  width: 200vw;
  height: 100vh;
  left: 20%;
  z-index: -0;
}
.evento2 {
  position: fixed;
  height: 100vh;
  width: 27%;
  left: 20%;
  background-image: url("../img/riempitivi/WEB/Vertical WEB/adults-alcohol-beverage-1559051.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -20px -0px;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
  background-color: #6e6a69;
  opacity: 0.6;
  cursor: pointer;
}
.evento3 {
  position: fixed;
  height: 100vh;
  width: 27%;
  left: 44%;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/hand-hindu-india-1321727.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -445px -0px;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
  background-color: #6e6a69;
  opacity: 0.6;
  cursor: pointer;
}
.evento4 {
  position: fixed;
  height: 100vh;
  width: 26%;
  left: 74%;
  background-image: url("../img/riempitivi/WEB/markus-spiske-5gGcn2PRrtc-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -350px -0px;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  background-color: #6e6a69;
  opacity: 0.6;
  cursor: pointer;
}
.evento5 {
  position: fixed;
  height: 100vh;
  width: 27%;
  left: 100%;
  background-image: url("../img/riempitivi/WEB/Vertical WEB/alex-loup-wuklwjKx6BU-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  background-color: #6e6a69;
  opacity: 0.6;
  cursor: pointer;
}
.eventoText {
  position: absolute;
  width: 80%;
  padding: 0 10%;
  top: 42vh;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoText);
  color: white;
  z-index: -0;
  cursor: pointer;
  line-height: 43px;
}
.evento1Write {
  position: fixed;
  height: 100vh;
  width: 20%;
  top: 42vh;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoText);
  color: white;
  z-index: -0;
  cursor: pointer;
  text-decoration: underline;
  line-height: 43px;
}
.evento2Write {
  position: fixed;
  height: 100vh;
  width: 27%;
  top: 42vh;
  left: 20%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoText);
  color: white;
  z-index: 0;
  cursor: pointer;
  text-decoration: underline;
  line-height: 43px;
}
.evento3Write {
  position: fixed;
  height: 100vh;
  width: 27%;
  top: 42vh;
  left: 47%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoText);
  color: white;
  cursor: pointer;
  text-decoration: underline;
  line-height: 43px;
}
.evento4Write {
  position: fixed;
  height: 100vh;
  width: 26%;
  top: 42vh;
  left: 74%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoText);
  color: white;
  cursor: pointer;
  text-decoration: underline;
  line-height: 43px;
}
.evento5Write {
  position: fixed;
  height: 100vh;
  width: 26%;
  top: 42vh;
  left: 100%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoText);
  color: white;
  cursor: pointer;
  text-decoration: underline;
  line-height: 43px;
}

.eventoDate {
  position: absolute;
  width: 26%;
  top: 60vh;
  left: 0%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoDate);
  color: white;
}
.evento1Date {
  position: fixed;
  height: 100vh;
  width: 26%;
  top: 60vh;
  left: 0%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoDate);
  color: white;
}
.evento2Date {
  position: fixed;
  height: 100vh;
  width: 26%;
  top: 60vh;
  left: 20%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoDate);
  color: white;
}
.evento3Date {
  position: fixed;
  height: 100vh;
  width: 26%;
  top: 60vh;
  left: 47%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoDate);
  color: white;
}
.evento4Date {
  position: fixed;
  height: 100vh;
  width: 26%;
  top: 60vh;
  left: 74%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoDate);
  color: white;
}
.evento5Date {
  position: fixed;
  height: 100vh;
  width: 26%;
  top: 60vh;
  left: 100%;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--eventoDate);
  color: white;
}
#container .box {
  width: 27vw;
  height: 100vh;
  display: inline-block;
  position: relative;
  z-index: -0;
}
#container .box > div {
  width: 27vw;
  height: 100vh;
  color: #fff;

  position: absolute;
  left: 0%;
  margin: 0px 0 0 -0px;
  z-index: -0;
  overflow-wrap: break-word;
}
#container {
  overflow-y: scroll;
  overflow-x: hidden;
  transform: rotate(270deg) translateX(-100%);
  transform-origin: top left;
  background-color: #999;
  position: absolute;
  width: 100vh;
  height: 100vw;
  -ms-overflow-style: none;
  z-index: -0;
}
#container::-webkit-scrollbar {
  display: none;
}

#container2 {
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
  width: 127vw;
  height: 100vh;
  font-size: 0;
  z-index: -3;
}

.scrollArrowContainer {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 2.5%;
  z-index: 1;
  position: absolute;
  bottom: 8%;
  left: 55%;
  transition: transform 250ms ease-in-out;
}

.scrollArrow {
  transition: 250ms ease-in-out;
}
.scrollArrow:hover {
  transform: scaleX(1.4);
}

.scrollArrowWrite {
  position: fixed;
  height: 304px;
  width: 61px;
  left: 49.5%;
  margin-left: -64px;
  top: 92%;
  z-index: 0;
  color: white;
  font-size: 2.8vh;
}
.pianoMobile {
  height: 15%;
  border-bottom: 2px solid #91855d;
  /* border-top: 1px solid #91855d; */
  font-size: 24px;
  color: #91855d;
  margin-left: 0%;
  border-left: white;
  border-right: white;
  padding: 12px 32px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pianoZero {
  height: 10%;
  padding-top: 10%;
  line-height: 43px;
}

.PianoTerra,
.GroundFloor {
  height: 15%;
  border: 1px solid #91855d;
  font-size: var(--floorTextSize);
  color: #91855d;
  margin-left: 0%;
  border-left: white;
  border-right: white;
  padding-left: 10.5vw;
  padding-top: 2%;
  padding-bottom: 2%;
  cursor: pointer;
  line-height: 43px;
  display: flex;
}
.PianoPrimo,
.FirstFloor {
  height: 100%;
  border: 1px solid #91855d;
  font-size: var(--floorTextSize);
  color: #91855d;
  margin-top: 0%;
  margin-left: 0%;
  border-left: white;
  border-right: white;
  padding-left: 10.5vw;
  padding-top: 2%;
  padding-bottom: 2%;
  cursor: pointer;
  line-height: 43px;
  display: flex;
}

.PianoSecondo,
.SecondFloor {
  height: 15%;
  border: 1px solid #91855d;
  font-size: var(--floorTextSize);
  color: #91855d;
  margin-top: 0%;
  margin-left: 0%;
  border-left: white;
  border-right: white;
  padding-left: 10.5vw;
  padding-top: 2%;
  padding-bottom: 2%;
  cursor: pointer;
  line-height: 43px;
  display: flex;
}
.PianoTerzo,
.ThirdFloor {
  height: 15%;
  border: 1px solid #91855d;
  font-size: var(--floorTextSize);
  color: #91855d;
  margin-top: 0%;
  margin-left: 0%;
  border-left: white;
  border-right: white;
  padding-left: 10.5vw;
  padding-top: 2%;
  padding-bottom: 2%;
  cursor: pointer;
  line-height: 43px;
  display: flex;
}
.PianoQuarto,
.FourthFloor {
  height: 15%;
  border: 1px solid #91855d;
  font-size: var(--floorTextSize);
  color: #91855d;
  margin-top: 0%;
  margin-left: 0%;
  border-left: white;
  border-right: white;
  padding-left: 10.8vw;
  padding-top: 2%;
  padding-bottom: 2%;
  cursor: pointer;
  line-height: 43px;
  display: flex;
}
.pianoOpened {
  background-color: #91855d;
  color: white;
}
.singleApartmentMobile {
  color: white;
  /* padding-left: 5%; */
  font-size: 20px;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
}
.singleApartment {
  color: white;
  /* padding-left: 5%; */
  font-size: 20px;
  cursor: pointer;
  text-decoration: underline;
}
.apartmentHeroImage {
  position: absolute;
  height: 85vh;
  width: 100%;
  /* left: 0%; */
  /* background-image: url("../img/riempitivi/WEB/Horizontal WEB/kari-shea-cpEO3HoKdKM-unsplash.jpg"); */
  /* background-repeat: no-repeat; */
  background-size: cover;
  background-position: center;
  z-index: -3;
  /* zoom: 100%; */
  /* margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 1; */
}
.apartmentMainImage {
  position: absolute;
  height: 85vh;
  width: 100%;
  left: 0%;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/kari-shea-cpEO3HoKdKM-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 1;
}
.backgroundBlackMainImage {
  position: absolute;
  height: 85vh;
  width: 100%;
  left: 0%;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 0.5;
  background-color: black;
}
.apartmentMainImage1 {
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 0vh;
  background-color: black;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 1;
}
.apartmentMainImage1::after {
  content: "";
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 0vh;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/kari-shea-cpEO3HoKdKM-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 0.6;
}
.apartmentMainImage2 {
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 17vh;
  background-color: black;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 1;
}
.apartmentMainImage2::after {
  content: "";
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 0vh;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/kari-shea-cpEO3HoKdKM-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -17vh;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 0.6;
}
.apartmentMainImage3 {
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 34vh;
  background-color: black;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 1;
}
.apartmentMainImage3::after {
  content: "";
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 0vh;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/kari-shea-cpEO3HoKdKM-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -34vh;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 0.6;
}
.apartmentMainImage4 {
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 51vh;
  background-color: black;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 1;
}
.apartmentMainImage4::after {
  content: "";
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 0vh;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/kari-shea-cpEO3HoKdKM-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -51vh;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 0.6;
}
.apartmentMainImage5 {
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 68vh;
  background-color: black;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 1;
}
.apartmentMainImage5::after {
  content: "";
  position: absolute;
  height: 17vh;
  width: 100%;
  left: 0%;
  top: 0vh;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/kari-shea-cpEO3HoKdKM-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -68vh;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  opacity: 0.6;
}

@media screen and (min-width: 601px) {
  .apartmentTitle {
    position: absolute;
    width: 30vw;
    text-align: center;
    overflow-wrap: normal;
    font-size: var(--bigMediumText);
    left: 50%;
    margin-left: -15vw;
    top: 29vh;
    margin-bottom: 0%;
    color: white;
    line-height: 60px;
  }
  .apartmentNumber {
    position: absolute;
    width: 30vw;
    text-align: center;
    overflow-wrap: normal;
    font-size: var(--enormousText);
    left: 50%;
    margin-left: -15vw;
    top: 38vh;
    margin-bottom: 0%;
    color: white;
    line-height: 60px;
  }
}

@media screen and (max-width: 600px) {
  .apartmentTitle {
    position: absolute;
    width: 100%;
    text-align: center;
    overflow-wrap: normal;
    font-size: 40px;
    top: 35vh;
    margin-bottom: 0%;
    color: white;
  }
  .apartmentNumber {
    position: absolute;
    width: 100%;
    text-align: center;
    overflow-wrap: normal;
    font-size: 66px;
    /* left: 50%; */
    /* margin-left: -15vw; */
    top: 41vh;
    margin-bottom: 0%;
    color: white;
    /* line-height: 60px; */
  }
}

.apartmentBackButtonWrite {
  position: absolute;
  left: 2.6%;
  margin-left: -0px;
  top: 37%;
  margin-bottom: 0%;
  z-index: 0;
  color: white;
  cursor: pointer;
  font-size: var(--arrowWrite);
}

.apartmentBackButtonWrite:hover {
  transform: scaleX(1) rotate(0deg);
}

.apartmentBackButton {
  position: absolute;
  height: 60px;
  width: 302px;
  background-image: url("../img/Export_Asset/Forward_B_1.png");
  background-repeat: no-repeat;
  left: 2.5%;
  margin-left: -0px;
  top: 41%;
  margin-bottom: 0%;
  transform: rotate(180deg);
  z-index: 0;
  zoom: 25%;
  cursor: pointer;
  transition: transform 250ms ease-in-out;
}
.apartmentBackButton:hover {
  transform: scaleX(1.3) rotate(180deg);
}

.simpleDownArrowWrite {
  position: absolute;
  left: 50%;
  width: 10%;
  top: 65%;
  margin-bottom: 0%;
  margin-left: -5%;
  text-align: center;
  color: white;
  transition: 500ms;
  font-size: var(--arrowWrite);
}
.simpleDownArrow {
  position: absolute;
  height: 60px;
  width: 302px;
  background-image: url("../img/Export_Asset/Forward_B_1.png");
  background-repeat: no-repeat;
  left: 50%;
  margin-left: -151px;
  z-index: 0;
  zoom: 30%;
  top: 74%;
  transform: rotate(90deg);
  margin-bottom: 0vh;
  transition: transform 300ms ease-in-out;
}
.simpleDownArrow:hover {
  transform: scaleY(1.3) rotate(90deg);
}

.simpleDownArrowMobile {
  position: absolute;
  height: 56px;
  width: 56px;
  background-image: url("../img/arrow_down_mobile.png");
  background-repeat: no-repeat;
  left: calc(50% - 24px);
  z-index: 0;
  top: 74%;
  margin-bottom: 0vh;
  transition: transform 300ms ease-in-out;
}

.menuDownButtonCenteredRelative {
}
.availabilityAndContact {
  position: absolute;
  top: 90vh;
  width: 60vw;
  padding: 0 20vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.infoTable {
  position: absolute;
  margin-top: 0vh;
  width: 100%;
  top: 95vh;
}
.aptInfo {
  color: #91855d;
  font-size: var(--apartmentMediumText);
}
.aptInfoContattaci {
  color: #91855d;
  font-size: var(--mediumText);
  text-decoration: underline;
  cursor: pointer;
}

.leftPart {
  position: relative;
  top: 10vh;

  color: #91855d;
}
.leftPart1 {
  position: absolute;
  top: 0vh;
  left: 28%;
  font-size: var(--apartmentMediumText);
}
.hr1 {
  position: absolute;
  width: 44%;
  left: 50%;
  margin-left: -22%;
  top: 4vh;
}
.leftPart2 {
  position: absolute;
  top: 8vh;
  left: 28%;
  font-size: var(--apartmentMediumText);
}
.hr2 {
  position: absolute;
  width: 22%;
  left: 50%;
  margin-left: -22%;
  top: 12vh;
}
.leftPart3 {
  position: absolute;
  top: 16vh;
  left: 28%;
  margin-bottom: 3vh;
  font-size: var(--apartmentMediumText);
}
.hr3 {
  position: absolute;
  width: 22%;
  left: 50%;
  margin-left: -22%;
  top: 20vh;
}
.leftPart4 {
  position: absolute;
  width: 15%;
  overflow-wrap: break-word;
  top: 24vh;
  left: 28%;
  color: #3f3f3f;
  font-size: var(--apartmentDescriptionText);
}
.verticalLine {
  position: relative;
  /* width: 90vh; */
  width: 80vh;
  transform: rotate(90deg);
  top: 48vh;
  left: 0.1vw;
  /* max-width: 90vh; */
}
@media screen and (min-width: 1920px) {
  .verticalLine {
    width: 66vh;
    top: 40vh;
  }
}

@media screen and (max-width: 1370px) {
  .verticalLine {
    width: 110vh;
    top: 62vh;
  }
}
.squaredMeters {
  position: absolute;
  left: 51%;
  top: 10vh;
  font-size: var(--apartmentMediumText);
  color: #91855d;
}
.tableSetting {
  position: absolute;
  width: 100%;
  top: 145vh;
  height: auto;
}
.firstColumn {
  position: relative;
  height: 90vh;
  width: 17.5vw;
  left: 16%;
  top: 62vh;
}
.img11 {
  height: 45vh;
  width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/apartment-bed-bedroom-545034.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -90px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
  background-color: #6e6a69;
}
.img21 {
  height: 45vh;
  width: 20vw;
  max-width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/crew-1pJjYDJW5HM-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -85px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -0%;
  margin-top: 3.6vh;
  background-color: #6e6a69;
}
.secondColumn {
  position: relative;
  height: 90vh;
  width: 17.5vw;
  left: 37%;
  top: 0vh;
  margin-top: -28vh;
}
.img12 {
  height: 24vh;
  width: 12.5vw;
  max-width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/nirzar-pangarkar-CswKfD546Z8-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -40px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -0%;
  margin-top: 0%;
}
.img22 {
  height: 45vh;
  width: 12.5vw;
  max-width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -40px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -0%;
  margin-top: 3.6vh;
}
.img32 {
  height: 17.2vh;
  width: 12.5vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/erick-lee-hodge-el_V6z_h5nA-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -0%;
  margin-top: 3.6vh;
}
.thirdColumn {
  position: relative;
  height: 90vh;
  width: 17.5vw;
  left: 50.75%;
  top: 3.6vh;
  margin-top: -93.5vh;
}
.img13 {
  height: 35vh;
  width: 20vw;
  max-width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Horizontal WEB/kari-shea-zUNQBoXlV5w-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -75px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -0%;
  margin-top: 0%;
}
.img23 {
  height: 54.8vh;
  width: 20vw;
  max-width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Vertical WEB/kaylee-garrett-GDh2CXyQjmM-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -0%;
  margin-top: 3.6vh;
}
.fourthColumn {
  position: relative;
  height: 90vh;
  width: 17.5vw;
  left: 71.5%;
  top: 0vh;
  margin-top: -86.5vh;
}
.img14 {
  height: 93.6vh;
  width: 12.5vw;
  max-width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Vertical WEB/element5-digital-2cwqJgtQ3D0-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -285px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -0%;
  margin-top: 0%;
}

.previousRoomButtonMobile-exited {
  /* position: absolute; */
  display: flex;
  height: 10vh;
  width: 49vw;
  border: #91855d;
  border-style: ridge;
  border-width: 0.5vw;
  text-align: center;
  /* padding-top: 0vh; */
  /* top: 315vh; */
  color: #91855d;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
}
.previousRoomButtonMobile-entering {
  /* position: absolute; */
  display: flex;
  height: 10vh;
  width: 49vw;
  border: #91855d;
  border-style: ridge;
  border-width: 0.5vw;
  text-align: center;
  /* padding-top: 0vh; */
  /* top: 315vh; */
  color: #91855d;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
}
.previousRoomButtonMobile-entered {
  /* position: absolute; */
  display: flex;
  height: 10vh;
  width: 49vw;
  border: #91855d;
  border-style: ridge;
  border-width: 0.5vw;
  text-align: center;
  /* padding-top: 0vh; */
  /* top: 315vh; */
  background-color: #91855d;
  color: #e6ce3c;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
  font-size: var(--apartmentMediumText);
}
.nextRoomButtonMobile-exited {
  /* position: absolute; */
  display: flex;
  height: 10vh;
  width: 49vw;
  border: #91855d;
  border-style: ridge;
  border-width: 0.5vw;
  text-align: center;
  /* padding-top: 0vh; */
  /* left: 50vw; */
  /* top: 315vh; */
  color: #91855d;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
}
.nextRoomButtonMobile-entering {
  /* position: absolute; */
  display: flex;
  height: 10vh;
  width: 49vw;
  border: #91855d;
  border-style: ridge;
  border-width: 0.5vw;
  text-align: center;
  /* padding-top: 0vh; */
  /* left: 50vw; */
  /* top: 315vh; */
  background-color: transparent;
  color: #91855d;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
}
.nextRoomButtonMobile-entered {
  /* position: absolute; */
  display: flex;
  height: 10vh;
  width: 49vw;
  border: #91855d;
  border-style: ridge;
  border-width: 0.5vw;
  text-align: center;
  padding-top: 0vh;
  /* left: 50vw; */
  /* top: 315vh; */
  background-color: #91855d;
  color: #e6ce3c;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
  transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
}
.previousRoomButton-exited {
  position: absolute;
  display: flex;
  height: 5vh;
  width: 50vw;
  border: #91855d;
  border-style: ridge;
  border-width: 1px;
  text-align: center;
  padding-top: 0vh;
  top: 360vh;
  color: #91855d;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
}
.previousRoomButton-entering {
  position: absolute;
  display: flex;
  height: 5vh;
  width: 50vw;
  border: #91855d;
  border-style: ridge;
  border-width: 1px;
  text-align: center;
  padding-top: 0vh;
  top: 360vh;
  color: #91855d;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
}
.previousRoomButton-entered {
  position: absolute;
  display: flex;
  height: 5vh;
  width: 50vw;
  border: #91855d;
  border-style: ridge;
  border-width: 1px;
  text-align: center;
  padding-top: 0vh;
  top: 360vh;
  background-color: #91855d;
  color: #e6ce3c;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
  font-size: var(--apartmentMediumText);
}
.nextRoomButton-exited {
  position: absolute;
  display: flex;
  height: 5vh;
  width: 50vw;
  border: #91855d;
  border-style: ridge;
  border-width: 1px;
  text-align: center;
  padding-top: 0vh;
  left: 50vw;
  top: 360vh;
  color: #91855d;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
}
.nextRoomButton-entering {
  position: absolute;
  display: flex;
  height: 5vh;
  width: 50vw;
  border: #91855d;
  border-style: ridge;
  border-width: 1px;
  text-align: center;
  padding-top: 0vh;
  left: 50vw;
  top: 360vh;
  background-color: transparent;
  color: #91855d;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
}
.nextRoomButton-entered {
  position: absolute;
  display: flex;
  height: 5vh;
  width: 50vw;
  border: #91855d;
  border-style: ridge;
  border-width: 1px;
  text-align: center;
  padding-top: 0vh;
  left: 50vw;
  top: 360vh;
  background-color: #91855d;
  color: #e6ce3c;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
  font-size: var(--apartmentMediumText);
  transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
}
.absoluteBottomMenu {
  position: absolute;
  top: 370vh;
  width: 100vw;
  left: 0%;
  margin-left: -0%;
}
.bottomMenu {
  position: relative;
  padding-left: 2vw;
  margin-top: 5vh;
}
.miniHrVertical {
  position: absolute;
  width: 1vw;
  top: calc(4.2vh - 0.55vw);
  left: 3.5vw;
  transform: rotate(90deg);
  margin-top: -0%;
  color: #91855d;
}
.bottomMenuItem {
  position: relative;
  top: 2vh;
  left: -1.5vw;
  margin-left: 5vw;
  color: #91855d;
  font-size: var(--bottomMenuItem);
  cursor: pointer;
  text-decoration: underline;
}
.privacyPolicy {
  position: absolute;
  top: 2vh;
  right: 12.5vw;
  margin-left: 0vw;
  color: #91855d;
  font-size: var(--bottomMenuItem);
  cursor: pointer;
  text-decoration: underline;
}
.facebookIcon {
  height: 3vh;
  width: 3vh;
  top: 1.4vh;
  right: 5vw;
  position: absolute;
  background-image: url("../img/facebook-3-xl.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -60%;
  margin-top: 0%;
  cursor: pointer;
}
.instagramIcon {
  height: 3vh;
  width: 3vh;
  position: absolute;
  top: 1.4vh;
  right: 8.5vw;
  background-image: url("../img/instagram.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: -60%;
  margin-top: 0%;
  cursor: pointer;
}
.whiteBottomSpace {
  margin-top: 10vh;
}

.fixedBottomMenu {
  position: fixed;
  width: 100vw;
  top: 90vh;
  left: 0%;
  margin-left: -0%;
  z-index: 1050;
}
.Contact {
  position: fixed;
  background-color: white;
  height: 100%;
  width: 100%;
}
.hiddenElement {
  position: fixed;
  z-index: 10000;
  color: transparent;
}
.contactUpperDiv {
  position: fixed;
  z-index: 900;
  height: 100vh;
  width: 100vw;
  background-color: white;
}

.contactMainTitle {
  position: fixed;
  text-align: center;
  color: #91855d;
  font-size: var(--particularSectionTitle);
  top: 15%;
  left: 10.5%;
  z-index: 1000;
}

/* @media screen and (max-width: 600px) {
    .contactMainTitle {
        font-size: 32px;
    }
} */

.contactSubTitle {
  position: fixed;
  left: 10.5%;
  top: 25.5%;
  width: 38%;
  overflow-wrap: break-word;
  font-size: var(--smallText);
  color: #3f3f3f;
  line-height: 1.5em;
  z-index: 1000;
}

@media screen and (max-width: 600px) {
  .contactSubTitle {
    width: 65%;
  }
}

.contactInputLabel {
  font-size: var(--mediumText);
  color: #91855d;
  z-index: 1000;
}

.contactInput {
  font-size: var(--mediumText);
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #6c6c6c;
  margin-bottom: 24px;
  /* z-index: 1200; */
  width: 100%;
}

.contactInputArea {
  width: 100%;
  height: 100px;
  z-index: 1010;
  resize: none;
}

.contactSendButtonMobile {
  font-size: var(--mediumText);
  color: #91855d;
  z-index: 1050;
  background-color: white;
  box-shadow: 0;
  border: 1px solid #999;
}

.contactName {
  position: fixed;
  left: 10.5%;
  top: 47%;
  overflow-wrap: break-word;
  font-size: var(--mediumText);
  color: #91855d;
  z-index: 1000;
}
.contactNameInput {
  position: fixed;
  left: 10.5%;
  top: 51%;
  width: 400px;
  /* overflow-wrap: break-word; */
  font-size: var(--mediumText);
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #6c6c6c;
  z-index: 1200;
}
.contactEmail {
  position: fixed;
  left: 10.5%;
  top: 59%;
  overflow-wrap: break-word;
  font-size: var(--mediumText);
  color: #91855d;
  z-index: 900;
}
.contactEmailInput {
  position: fixed;
  width: 400px;
  left: 10.5%;
  top: 63%;
  overflow-wrap: break-word;
  font-size: var(--mediumText);
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #6c6c6c;
  z-index: 1200;
}

.contactPrivacy {
  position: fixed;
  left: 10.5%;
  top: 71%;
  overflow-wrap: break-word;
  font-size: var(--mediumText);
  color: #91855d;
  z-index: 900;
}
.contactMessage {
  position: fixed;
  left: 51.6%;
  top: 47%;
  overflow-wrap: break-word;
  font-size: var(--mediumText);
  color: #91855d;
  z-index: 900;
}
.contactSendButton {
  position: fixed;
  height: 6%;
  width: 6.5%;
  left: 83%;
  top: 73.8%;
  overflow-wrap: break-word;
  font-size: var(--mediumText);
  color: #91855d;
  z-index: 1050;
  background-color: white;
  box-shadow: 0;
  border: 1px solid #999;
}
.contactTextArea {
  position: fixed;
  right: 18%;
  bottom: 20.2%;
  width: 30%;
  height: 28%;
  z-index: 1010;
  resize: none;
}

.contactCheckBox {
  position: fixed;
  left: 10.5%;
  top: 75.5%;
  font-size: var(--smallText);
  z-index: 2;
  color: #6c6c6c;
  z-index: 1200;
}

.logoRelative {
  position: relative;
  height: 259px;
  width: 812px;
  background-image: url("../img/Export_Asset/Layer 2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0vh;
  margin-left: 15vw;
  padding-top: 0vh;
  z-index: 2000;
  cursor: pointer;

  zoom: 19%;
}

@media screen and (min-width: 601px) {
  .mapCentered {
    position: relative;
    height: 450px;
    width: 900px;
    left: 50%;
    margin-top: 6vh;
    margin-left: -450px;
    z-index: 0;
  }
}

@media screen and (max-width: 600px) {
  .mapCentered {
    position: relative;
    height: 450px;
    width: "100%";
    margin-top: 6vh;
    z-index: 0;
  }
  .innerMapCentered {
    position: relative;
    left: 0;
    z-index: 0;
  }
}

.cioCheServeMainTitle {
  position: relative;
  color: #91855d;
  font-size: var(--particularSectionTitle);
  margin-top: 3.5vh;
  left: 10.5%;
}
.cioCheServeSubTitle {
  position: relative;
  left: 10.5%;
  margin-top: 4.5vh;
  width: 39%;
  overflow-wrap: break-word;
  font-size: var(--smallText);
  line-height: var(--floorTextSize);
  color: #3f3f3f;
}
@media screen and (max-width: 600px) {
  .cioCheServeMainTitle {
    font-size: 32px;
    margin-top: 4.5vh;
  }
  .cioCheServeSubTitle {
    width: 70%;
    margin-top: 3vh;
  }
}
.mapMenu {
  position: relative;
  display: block;
  text-align: center;
  width: 100%;
  left: 0%;
  margin-left: 1.5%;
  margin-top: 10.6vh;
}

@media screen and (max-width: 600px) {
  .mapMenu {
    width: unset;
    left: 0%;
    margin-left: 10.5%;
    margin-top: 3.6vh;
    overflow: scroll;
    height: 60px;
    padding-right: 30px;
  }
}

.mapMenuItem {
  position: relative;
  top: 2vh;
  left: -0vw;
  margin-right: 3vw;
  color: #91855d;
  font-size: var(--mediumText);
  cursor: pointer;
  text-decoration: underline;
}
.cioCheServeUpperWhitePart {
  height: 15vh;
  min-height: 120px;
}
.calendarioEventiMainImage {
  position: relative;
  height: 97.55vh;
  width: 100%;
  left: 0%;
  background-image: url("../img/riempitivi/WEB/priscilla-du-preez-nF8xhLMmg0c-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -00px;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  background-color: #6e6a69;
  opacity: 0.8;
}
.calendarioEventiTitle {
  font-size: var(--mediumBigText);
  color: #91855d;
  margin-top: 16px;
}
.calendarioEventiDescription {
  font-size: var(--smallText);
  line-height: 28px;
  margin-top: 16px;
}
.calendarioEventiBackButton {
  position: absolute;
  height: 60px;
  width: 302px;
  background-image: url("../img/Export_Asset/Forward_B_1.png");
  background-repeat: no-repeat;
  left: 2.5%;
  margin-left: -0px;
  margin-top: -30px;
  margin-bottom: 0%;
  top: 43.5%;
  transform: rotate(180deg);
  z-index: 0;
  zoom: 25%;
}
.calendarioEventiBackButtonWrite {
  position: absolute;
  left: 3.65%;
  top: 40vh;
  margin-left: -0px;
  margin-top: -0%;
  margin-bottom: 0%;
  z-index: 0;
  color: white;
  cursor: pointer;
  font-size: var(--arrowWrite);
}
.calendarSimpleDownArrowWrite {
  position: relative;
  margin-bottom: 11.7%;
  margin-top: -13.5%;
  left: 50%;
  width: 10%;
  margin-left: -5%;
  text-align: center;
  color: white;
  font-size: var(--arrowWrite);
}
.calendarSimpleDownArrow {
  position: relative;
  height: 60px;
  width: 302px;
  background-image: url("../img/Export_Asset/Forward_B_1.png");
  background-repeat: no-repeat;
  left: 50%;
  margin-left: -151px;
  top: 68%;
  z-index: 0;
  zoom: 30%;
  transform: rotate(90deg);
  margin-bottom: 7%;
  margin-top: -4.5%;
}

.imageContainer {
  height: 50vh;
  position: relative;
  background-color: #ffe533;

  width: 65vw;
  margin-left: -30vw;
}
.firstEventImage {
  left: 0vw;
  height: 50vh;
  width: 65vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Inaugu WEB/kelsey-chance-ZrhtQyGFG6s-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -80px -000px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-top: 31vh;
  margin-left: 80px;
}
.calendarLeftProperty {
  position: relative;
  left: 50vw;
}
.secondEventImage {
  left: 0vw;
  height: 50vh;
  width: 65vw;

  position: relative;
  background-image: url("../img/riempitivi/WEB/Inaugu WEB/alasdair-elmes-ULHxWq8reao-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -80px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-top: 18vh;
  margin-left: 80px;
}
.thirdEventImage {
  left: 0vw;
  height: 50vh;
  width: 65vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Inaugu WEB/samantha-gades-fIHozNWfcvs-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -80px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-top: 31vh;
  margin-left: 80px;
}
.eventTitle {
  position: relative;
  color: #91855d;
  font-size: var(--eventTitle);
  top: 15%;
  margin-left: -30vw;
  margin-top: 3vh;
}
.eventText {
  position: relative;
  top: 25.5%;
  width: 38%;
  overflow-wrap: break-word;
  font-size: var(--smallText);
  color: #6c6c6c;
  line-height: 1.5em;
  margin-left: -30vw;
  margin-top: 3vh;
}
.bottomEventCalendarWhiteSpace {
  height: 14vh;
}
.aperitivoMainImage {
  position: relative;
  height: 97.55vh;
  width: 100%;
  left: 0%;
  background-image: url("../img/riempitivi/WEB/Vertical WEB/adults-alcohol-beverage-1559051.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -650px;
  z-index: -3;
  zoom: 100%;
  margin-left: -0%;
  margin-top: -0%;
  text-align: center;
  background-color: #6e6a69;
  opacity: 0.75;
}

.aperitivoTitle {
  position: absolute;
  width: 40vw;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--calendarioEventiTitle);
  left: 50%;
  margin-left: -20vw;
  top: 40%;
  margin-bottom: 21%;
  color: white;
}
.aperitivoDate {
  position: absolute;
  width: 30vw;
  text-align: center;
  overflow-wrap: break-word;
  font-size: var(--floorTextSize);
  left: 50%;
  margin-left: -15vw;
  margin-top: -16%;
  margin-bottom: 16.5%;
  color: white;
}
.aperitivoContent {
  position: absolute;
  width: 100%;
}
.aperitivoFirstColumn {
  position: absolute;
  left: 10vw;
  width: 35vw;
  top: 10vh;
}
.aperitivoFCTitle {
  position: relative;
  color: #91855d;
  font-size: var(--mediumBigText);
  line-height: 43px;
}
.aperitivoFCText {
  color: #3f3f3f;
  font-size: var(--smallText);
  line-height: 28px;
  margin-top: 24px;
}
.aperitivoSecondColumn {
  position: absolute;
  left: 51%;
  top: 10vh;
  width: 100%;
}
.aperitivoImg1 {
  height: 30vh;
  width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Ape WEB/jessica-ruscello-AbuQX0g4cdU-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -12px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
}
.aperitivoImg2 {
  height: 30vh;
  width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Ape WEB/scott-warman-rrYF1RfotSM-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -12px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-top: 2.5vh;
}
.aperitivoImg3 {
  height: 62.5vh;
  width: 20vw;
  left: 20.25%;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Ape WEB/justin-aikin-x-6HB8_qE4A-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -12px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: 1.5vw;
  margin-top: -62.5vh;
}
.aperitivoImg4 {
  height: 30vh;
  width: 41.5vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Ape WEB/elevate-oRl7BoX7QCE-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -12px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-top: 2.5vh;
}
.aperitivoImg5 {
  height: 62.5vh;
  width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Ape WEB/elevate-Euyr_8yImIQ-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -12px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-top: 2.5vh;
}
.aperitivoImg6 {
  height: 30vh;
  width: 19.2vw;
  left: 20.25%;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Ape WEB/lana-abie-De3-PUfrO5Q-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: 1.5vw;
  margin-top: -62.5vh;
}

.aperitivoImg7 {
  height: 30vh;
  width: 19.2vw;
  left: 20.25%;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Ape WEB/louis-hansel-yAo8dahtHgg-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: 1.5vw;
  margin-top: 2.5vh;
}
.aperitivoImg8 {
  height: 30vh;
  width: 20vw;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Ape WEB/fineas-anton-kC1-9Snr9hI-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -130px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-top: 2.5vh;
}
.aperitivoImg9 {
  height: 30vh;
  width: 19.2vw;
  left: 20.25%;
  position: relative;
  background-image: url("../img/riempitivi/WEB/Ape WEB/filipe-de-rodrigues-i7Vy81pnZCI-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -140px;
  opacity: 1;
  z-index: 0;
  zoom: 100%;
  margin-left: 1.5vw;
  margin-top: -30vh;
}
.nextEventButton {
  position: absolute;
  height: 5vh;
  width: 50vw;
  border: #91855d;
  border-style: ridge;
  border-width: 1px;
  text-align: center;
  padding-top: 1.8vh;
  left: 51%;
  margin-top: 5vh;
  color: #91855d;
  text-decoration: underline;
  top: 310vh;
}
.bottomAperitivoWhiteSpace {
  height: 0vh;
}
.absoluteBottomMenuAperitivo {
  position: absolute;
  top: 324vh;
  width: 100vw;
  left: 0%;
  margin-left: -0%;
}
.slideTrackerConcept {
  right: 3%;
  top: 42%;
  position: fixed;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media screen and (max-width: 600px) {
  .slideTrackerConcept {
    display: none;
  }
}
.slideTrackerServizi {
  height: 1280px;
  width: 720px;
  left: 96%;
  top: 56.8%;
  position: fixed;
  background-image: url("../img/SlideTracker/2ServiziThin.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 18%;
  margin-top: -640px;
}
.slideTrackerAppartamenti {
  height: 1280px;
  width: 720px;
  left: 96%;
  top: 56.8%;
  position: fixed;
  background-image: url("../img/SlideTracker/3AppartamentiThin.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 18%;
  margin-top: -640px;
}
.slideTrackerEventi {
  height: 1280px;
  width: 720px;
  left: 96%;
  top: 56.8%;
  position: fixed;
  background-image: url("../img/SlideTracker/4EventiThin.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 18%;
  margin-top: -640px;
}
.slideTrackerVicinoANoi {
  height: 1280px;
  width: 720px;
  left: 96%;
  top: 56.8%;
  position: fixed;
  background-image: url("../img/SlideTracker/5VicinoANoiThin.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 0;
  zoom: 18%;
  margin-top: -640px;
}
.slideTrackerContact {
  height: 1280px;
  width: 720px;
  left: 96%;
  top: 56.8%;
  position: fixed;
  background-image: url("../img/SlideTracker/6ContactThin.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -0px -0px;
  opacity: 1;
  z-index: 900;
  zoom: 18%;
  margin-top: -640px;
}

.italianLanguageIt {
  position: fixed;
  height: 20px;
  left: 91.72vw;
  top: 5.4%;
  font-size: var(--smallText);
  color: #ffe533;
  text-decoration: underline;
  z-index: 1050;
  cursor: pointer;
  height: fit-content;
}
.englishLanguageIt {
  position: fixed;
  height: 20px;
  left: 93.3vw;
  top: 5.4%;
  font-size: var(--smallText);
  color: white;
  z-index: 1050;
  cursor: pointer;
  height: fit-content;
}
.italianLanguageEn {
  position: fixed;
  height: 20px;
  left: 91.72vw;
  top: 5.4%;
  font-size: var(--smallText);
  color: white;
  z-index: 1050;
  cursor: pointer;
  height: fit-content;
}
.englishLanguageEn {
  position: fixed;
  height: 20px;
  left: 93.3vw;
  top: 5.4%;
  font-size: var(--smallText);
  text-decoration: underline;
  color: #ffe533;
  z-index: 1050;
  cursor: pointer;
  height: fit-content;
}
.languageVerticalLine {
  position: fixed;
  width: 0.9%;
  top: 5.4vh;
  left: 92.4vw;
  transform: rotate(90deg);
  color: white;
  z-index: 1050;
  height: fit-content;
}
.italianLanguageRelativeIt {
  position: fixed;
  left: 91.72vw;
  top: 5.4vh;
  font-size: var(--smallText);
  color: #ffe533;
  text-decoration: underline;
  z-index: 1050;
  cursor: pointer;
  height: fit-content;
}
.englishLanguageRelativeIt {
  position: fixed;
  left: 93vw;
  top: 5.4vh;
  font-size: var(--smallText);
  z-index: 1050;
  cursor: pointer;
  height: fit-content;
}
.italianLanguageRelativeEn {
  position: fixed;
  left: 91.72vw;
  top: 5.4vh;
  font-size: var(--smallText);
  z-index: 1050;
  cursor: pointer;
  height: fit-content;
}
.englishLanguageRelativeEn {
  position: fixed;
  left: 93vw;
  top: 5.4vh;
  font-size: var(--smallText);
  color: #ffe533;
  text-decoration: underline;
  z-index: 1050;
  cursor: pointer;
  height: fit-content;
}
.languageVerticalLineRelative {
  position: fixed;
  width: 0.9%;
  top: 5.4vh;
  left: 92.25vw;
  transform: rotate(90deg);
  color: #91855d;
  z-index: 1050;
  height: fit-content;
}
.particularSectionRightContainer {
  position: fixed;
  left: 66%;
  width: 50%;
  max-width: 50%;
  z-index: -1;
  margin-left: -25%;
}
.particularSectionTitle {
  height: fit-content;
  font-size: var(--particularSectionTitle);
  font-style: normal;
  overflow-wrap: break-word;
  color: #91855d;
  text-align: left;
}
.particularSectionText {
  height: fit-content;
  font-size: var(--mediumText);
  line-height: 35px;
  overflow-wrap: break-word;
  letter-spacing: -1px;
}
@media screen and (max-width: 600px) {
  .particularSectionRightContainer {
    position: fixed;
    left: 0%;
    width: 100%;
    max-width: 100%;
    z-index: 0;
    margin-left: 0%;
  }
  .particularSectionTitle {
    height: fit-content;
    font-size: 28px;
    font-style: normal;
    overflow-wrap: break-word;
    color: white;
    text-align: center;
  }
  .particularSectionText {
    height: fit-content;
    font-size: var(--smallText);
    line-height: 24px;
    color: #91855d;
    overflow-wrap: break-word;
    letter-spacing: -1px;
    background-color: white;
    margin-top: 40px;
    padding: 40px;
  }
  .double {
    margin-top: 24px;
    padding: 24px;
  }
}

.greyBackground {
  background-color: rgba(110, 106, 105, 0.5);
}

.darkBackground {
  background-color: rgba(0, 0, 0, 1);
}

/*Piano terra*/
.apartmentSectionTitle0-appear,
.apartmentSectionTitle0-enter {
  opacity: 0;
  transform: translateX(600px);
}

.apartmentSectionTitle0-appear-active,
.apartmentSectionTitle0-enter.apartmentSectionTitle0-enter-active {
  opacity: 0.8;
  transform: translateX(0);
  transition: 800ms ease-in-out;
}

.apartmentSectionTitle0-exit {
  opacity: 1;
}
.apartmentSectionTitle0-exit.apartmentSectionTitle0-exit-active {
  opacity: 0;
  transition: 500ms linear;
}

/*Primo Piano*/
.apartmentSectionTitle1-appear,
.apartmentSectionTitle1-enter {
  opacity: 0;
  transform: translateX(600px);
}

.apartmentSectionTitle1-appear-active,
.apartmentSectionTitle1-enter.apartmentSectionTitle1-enter-active {
  opacity: 0.8;
  transform: translateX(0);
  transition: 500ms linear;
  transition-delay: 80ms;
}

.apartmentSectionTitle1-exit {
  opacity: 1;
  transform: translateX(0);
}
.apartmentSectionTitle1-exit.apartmentSectionTitle1-exit-active {
  transform: translateX(-400px);
  opacity: 0;
  transition: 500ms linear;
}

/*secondo Piano*/
.apartmentSectionTitle2-appear,
.apartmentSectionTitle2-enter {
  opacity: 0;
  transform: translateX(600px);
}

.apartmentSectionTitle2-appear-active,
.apartmentSectionTitle2-enter.apartmentSectionTitle2-enter-active {
  opacity: 0.8;
  transform: translateX(0);
  transition: 500ms linear;
  transition-delay: 160ms;
}

.apartmentSectionTitle2-exit {
  opacity: 1;
  transform: translateX(0);
}
.apartmentSectionTitle2-exit.apartmentSectionTitle2-exit-active {
  transform: translateX(-400px);
  opacity: 0;
  transition: 500ms linear;
}

/*Terzo Piano*/
.apartmentSectionTitle3-appear,
.apartmentSectionTitle3-enter {
  opacity: 0;
  transform: translateX(600px);
}

.apartmentSectionTitle3-appear-active,
.apartmentSectionTitle3-enter.apartmentSectionTitle3-enter-active {
  opacity: 0.8;
  transform: translateX(0);
  transition: 500ms linear;
  transition-delay: 240ms;
}

.apartmentSectionTitle3-exit {
  opacity: 1;
  transform: translateX(0);
}
.apartmentSectionTitle3-exit.apartmentSectionTitle3-exit-active {
  transform: translateX(-400px);
  opacity: 0;
  transition: 500ms linear;
}

/*Quarto Piano*/
.apartmentSectionTitle4-appear,
.apartmentSectionTitle4-enter {
  opacity: 0;
  transform: translateX(600px);
}

.apartmentSectionTitle4-appear-active,
.apartmentSectionTitle4-enter.apartmentSectionTitle4-enter-active {
  opacity: 0.8;
  transform: translateX(0);
  transition: 500ms linear;
  transition-delay: 320ms;
}

.apartmentSectionTitle4-exit {
  opacity: 1;
  transform: translateX(0);
}
.apartmentSectionTitle4-exit.apartmentSectionTitle4-exit-active {
  transform: translateX(-600px);
  opacity: 0;
  transition: 500ms linear;
}
.test1 {
  position: fixed;
  z-index: 4000;
  cursor: pointer;
  color: red;
}
.testLayer {
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 0;
  opacity: 0;
}

.calendario-eventi {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.calendario-eventi::after {
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background: url("/static/media/priscilla-du-preez-nF8xhLMmg0c-unsplash.95da3521.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
  height: 100vh;
}

.sectionTitleDelayed-appear,
.sectionTitleDelayed-enter {
  opacity: 0;
  transform: translateY(400px);
}

.sectionTitleDelayed-appear-active,
.sectionTitleDelayed-enter.sectionTitleDelayed-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: 1200ms ease-in-out;
  transition-delay: 800ms;
}

.sectionTitleDelayed-exit {
  opacity: 1;
}
.sectionTitleDelayed-exit.sectionTitleDelayed-exit-active {
  opacity: 0;
  transition: 1000ms linear;
}
.sectionTitleDelayed-leave {
  opacity: 1;
}
.sectionTitleDelayed-leave.sectionTitleDelayed-leave-active {
  opacity: 0;
  transition: 500ms linear;
}

.fadeInslideFromSx-appear,
.fadeInslideFromSx-enter {
  opacity: 0;
  transform: translateX(-400px);
}

.fadeInslideFromSx-appear-active,
.fadeInslideFromSx-enter.fadeInslideFromSx-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: 500ms linear;
}

.fadeInslideFromSx-exit {
  opacity: 1;
  transform: translateY(0);
}
.fadeInslideFromSx-exit.fadeInslideFromSx-exit-active {
  transform: translateY(-400px);
  opacity: 0;
  transition: 500ms linear;
}
.fadeInslideFromSx-leave {
  opacity: 1;
  transform: translateY(0);
}
.fadeInslideFromSx-leave.fadeInslideFromSx-leave-active {
  transform: translateY(-400px);
  opacity: 0;
  transition: 500ms linear;
}

.bm-item:hover {
  color: #e6ce3c;
  text-decoration: underline;
}

.box {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  /* background-color: #F44336; */
  /* height: 200px; */
  /* margin: 0 auto 0 auto; */
  /* transform-origin: bottom; */
  /* width: 200px; */
}
.bounce {
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

.bounceOnHover {
}
.bounceOnHover:hover {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-24px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-8px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.backArrow {
  transform: rotate(180deg);
  width: 90px;
  transition: transform 250ms ease-in-out;
}

.backArrow:hover {
  transform: scaleX(1.3) rotate(180deg);
}

.forwardArrow {
  transition: transform 250ms ease-in-out;
}

.forwardArrow:hover {
  transform: scaleX(1.3);
  transition: transform 250ms ease-in-out;
}

.downArrow {
  transform: rotate(90deg);
  width: 70px;
  transition: transform 250ms ease-in-out;
}

.downArrow:hover {
  transform: scaley(1.3) rotate(90deg);
}

@media screen and (min-width: 601px) {
  .downArrowMobile {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .downArrowMobile {
    position: absolute;
    bottom: 0;
    margin-bottom: 80px;
    left: 50%;
    margin-left: -50px;
    z-index: 1050;
  }
  .downArrow {
    width: 100px;
  }
}

.evento-container-entering {
  background-color: black;
  z-index: 1;
  position: absolute;
  width: 27vw;
  opacity: 0;
  /* margin-left: 20vw; */
  /* transition: width 500ms; */
  /* transition: width 5s */
}
.evento-container-entered {
  background-color: black;
  z-index: 1;
  position: absolute;
  width: 100vw;
  margin-left: 0 !important;
  opacity: 1;
  transition: opacity 700ms ease-in-out, width 1400ms ease-in-out,
    margin 1400ms ease-in-out;
}
.evento-container-exiting {
  background-color: black;
  z-index: 1;
  position: absolute;
  width: 100vw;
  margin-left: 0 !important;
  opacity: 1;
  /* transition: width 500ms; */
  /* transition: width 5s */
}
.evento-container-exited {
  background-color: black;
  z-index: 1;
  position: absolute;
  /* margin-left: 0 !important; */
  width: 0vw;
  opacity: 0;
  transition: opacity 1400ms ease-in-out, width 1400ms ease-in-out,
    margin 1400ms ease-in-out;
}

.sectionTitleSingleApartmentMobile-appear,
.sectionTitleSingleApartmentMobile-enter {
  opacity: 0;
  transform: translateY(1400px);
}

.sectionTitleSingleApartmentMobile-appear-active,
.sectionTitleSingleApartmentMobile-enter.sectionTitleSingleApartmentMobile-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: 500ms linear;
}

.sectionTitleSingleApartmentMobile-exit {
  opacity: 1;
  /* transform: rotate(00deg); */
}

.sectionTitleSingleApartmentMobile-exit.sectionTitleSingleApartmentMobile-exit-active {
  opacity: 0;
  /* transform: rotate(00deg); */
  transition: 000ms linear;
}

.sectionTitleSingleApartmentMobile-leave {
  opacity: 1;
  /* transform: rotate(0deg); */
}

.sectionTitleSingleApartmentMobile-leave.sectionTitleSingleApartmentMobile-leave-active {
  opacity: 0;
  transition: 500ms linear;
  /* transform: rotate(0deg); */
}

.sectionTitleSingleApartment-appear,
.sectionTitleSingleApartment-enter {
  opacity: 0;
  transform: translateY(1400px) rotate(90deg);
}

.sectionTitleSingleApartment-appear-active,
.sectionTitleSingleApartment-enter.sectionTitleSingleApartment-enter-active {
  opacity: 1;
  transform: translateY(0px) rotate(90deg);
  transition: 500ms linear;
}

.sectionTitleSingleApartment-exit {
  opacity: 1;
  transform: rotate(00deg);
}

.sectionTitleSingleApartment-exit.sectionTitleSingleApartment-exit-active {
  opacity: 0;
  transform: rotate(00deg);
  transition: 000ms linear;
}

.sectionTitleSingleApartment-leave {
  opacity: 1;
  transform: rotate(0deg);
}

.sectionTitleSingleApartment-leave.sectionTitleSingleApartment-leave-active {
  opacity: 0;
  transition: 500ms linear;
  transform: rotate(0deg);
}

.fadeInslideFromSxApartment-appear,
.fadeInslideFromSxApartment-enter {
  opacity: 0;
  transform: translateX(-1400px) rotate(180deg);
}

.fadeInslideFromSxApartment-appear-active,
.fadeInslideFromSxApartment-enter.fadeInslideFromSxApartment-enter-active {
  opacity: 1;
  transform: translateX(0) rotate(180deg);
  transition: 500ms linear;
}

.fadeInslideFromSxApartment-exit {
  opacity: 0;
  transform: rotate(180deg);
}
.fadeInslideFromSxApartment-exit.fadeInslideFromSxApartment-exit-active {
  transform: rotate(180deg);
  opacity: 0;
  transition: 500ms linear;
}
.fadeInslideFromSxApartment-leave {
  opacity: 0;
  transform: rotate(180deg);
}
.fadeInslideFromSxApartment-leave.fadeInslideFromSxApartment-leave-active {
  transform: rotate(180deg);
  opacity: 0;
  transition: 500ms linear;
}

.fadeInslideFromSxApartmentDownArrow-appear,
.fadeInslideFromSxApartmentDownArrow-enter {
  opacity: 0;
  transform: translateX(-1400px) rotate(90deg);
}

.fadeInslideFromSxApartmentDownArrow-appear-active,
.fadeInslideFromSxApartmentDownArrow-enter.fadeInslideFromSxApartmentDownArrow-enter-active {
  opacity: 1;
  transform: translateX(0) rotate(90deg);
  transition: 500ms linear;
}

.fadeInslideFromSxApartmentDownArrow-exit {
  opacity: 0;
  transform: rotate(190deg);
}
.fadeInslideFromSxApartmentDownArrow-exit.fadeInslideFromSxApartmentDownArrow-exit-active {
  transform: rotate(90deg);
  opacity: 0;
  transition: 500ms linear;
}
.fadeInslideFromSxApartmentDownArrow-leave {
  opacity: 0;
  transform: rotate(90deg);
}
.fadeInslideFromSxApartmentDownArrow-leave.fadeInslideFromSxApartmentDownArrow-leave-active {
  transform: rotate(90deg);
  opacity: 0;
  transition: 500ms linear;
}

.sectionTitle4Apartment-appear,
.sectionTitle4Apartment-enter {
  opacity: 0;
  transform: translateY(400px);
}

.sectionTitle4Apartment-appear-active,
.sectionTitle4Apartment-enter.sectionTitle4Apartment-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: 500ms linear;
}

.sectionTitle4Apartment-exit {
  opacity: 0;
}
.sectionTitle4Apartment-exit.sectionTitle4Apartment-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.sectionTitle4Apartment-leave {
  opacity: 0;
}
.sectionTitle4Apartment-leave.sectionTitle4Apartment-leave-active {
  opacity: 0;
  transition: 500ms linear;
}

.fadeInslideFromSxApartmentSingle-appear,
.fadeInslideFromSxApartmentSingle-enter {
  opacity: 0;
  transform: translateX(-1400px);
}

.fadeInslideFromSxApartmentSingle-appear-active,
.fadeInslideFromSxApartmentSingle-enter.fadeInslideFromSxApartmentSingle-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: 500ms linear;
}

.fadeInslideFromSxApartmentSingle-exit {
  opacity: 0;
}
.fadeInslideFromSxApartmentSingle-exit.fadeInslideFromSxApartmentSingle-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.fadeInslideFromSxApartmentSingle-leave {
  opacity: 0;
}
.fadeInslideFromSxApartmentSingle-leave.fadeInslideFromSxApartmentSingle-leave-active {
  opacity: 0;
  transition: 500ms linear;
}

.testpopup {
  z-index: 0;
  top: 50vh;
  left: 50vh;
  position: absolute;
}

.openedApartmentMap {
  display: flex;
  max-height: 80%;
  max-width: 80%;
  padding-top: 50vh;
  z-index: 2500;
}

.zoomIn {
  position: relative;
  z-index: 500;
  border: none;
  background-image: url("../img/icons8-zoom-in-30.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.zoomInMobile {
  /* position: relative; */
  z-index: 500;
  border: none;
  background-image: url("../img/icons8-zoom-in-30.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

/*Logo FadeIn Home*/
.fadeLogo-appear,
.fadeLogo-enter,
.fadeLogo-entering {
  opacity: 0;
  transform: scale(0.7, 0.7);
}

.fadeLogo-appear-active,
.fadeLogo-entered,
.fadeLogo-enter.fadeLogo-enter-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: 1400ms ease-in-out, 1400ms ease-in-out;
  transition-duration: 1400ms;
}
.fadeLogo-exit {
  opacity: 1;
}
.fadeLogo-exit.fadeLogo-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.fadeLogo-leave {
  opacity: 1;
}
.fadeLogo-leave.fadeLogo-leave-active {
  opacity: 0;
  transition: 500ms linear;
}

/*background FadeIn Home*/
.fadeBackground-appear,
.fadeBackground-enter {
  opacity: 0;
  transform: scale(1.1, 1.1);
}

.fadeBackground-appear-active,
.fadeBackground-enter.fadeBackground-enter-active {
  opacity: 1;
  transform: scale(1, 1);
  transition: 1400ms ease-in-out, 1400ms ease-in-out;
  transition-duration: 1400ms;
}

.fadeBackground-exit {
  opacity: 1;
}
.fadeBackground-exit.fadeBackground-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.fadeBackground-leave {
  opacity: 1;
}
.fadeBackground-leave.fadeBackground-leave-active {
  opacity: 0;
  transition: 500ms linear;
}

/*Fade SectionPresentation background*/
.fadeSection-appear,
.fadeSection-enter {
  opacity: 0;
}

.fadeSection-appear-active,
.fadeSection-enter.fadeSection-enter-active {
  opacity: 0.9;
  transition: 500ms linear;
}

.fadeSection-exit {
  opacity: 0.9;
}
.fadeSection-exit.fadeSection-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.fadeSection-leave {
  opacity: 0.9;
}
.fadeSection-leave.fadeSection-leave-active {
  opacity: 0;
  transition: 500ms linear;
}
/*fadein arrow home*/
.fadeArrow-appear,
.fadeArrow-enter {
  opacity: 0;
}

.fadeArrow-appear-active,
.fadeArrow-enter.fadeArrow-enter-active {
  opacity: 1;
  transition: 1400ms ease-in-out;
  transition-duration: 1400ms;
}

.fadeArrow-exit {
  opacity: 1;
}
.fadeArrow-exit.fadeArrow-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.fadeArrow-leave {
  opacity: 1;
}
.fadeArrow-leave.fadeArrow-leave-active {
  opacity: 0;
  transition: 500ms linear;
}

.crossOverlay {
  position: fixed;
  height: 64px;
  width: 64px;
  background-image: url("../img/crossIcon.png");
  background-repeat: no-repeat;
  right: 3vw;
  margin-right: -32px;
  top: 3vh;
  z-index: 1800;
  cursor: pointer;
  transition: transform 300ms ease-in-out;
}

/*ShortFade*/
.fadeShort-appear,
.fadeShort-enter {
  opacity: 0;
}

.fadeShort-appear-active,
.fadeShort-enter.fadeShort-enter-active {
  opacity: 1;
  transition: 200ms ease-in-out;
}

.fadeShort-exit {
  opacity: 1;
}
.fadeShort-exit.fadeShort-exit-active {
  opacity: 0;
  transition: 500ms linear;
}
.fadeShort-leave {
  opacity: 1;
}
.fadeShort-leave.fadeShort-leave-active {
  opacity: 0;
  transition: 500ms linear;
}

#grow {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0;
  overflow: hidden;
}

.prenotaBtn {
  padding: 12px 24px;
  background-color: #91855d;
  color: white;
  border: none;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  margin-right: 12px;
}
.prenotaBtn:hover {
  opacity: 0.8;
}
